.switch-lang {
  position: relative;
  font-size: 1.3rem;
  --size: 1.3rem
}

.switch-lang__icon {
  width: var(--size);
  height: var(--size)
}

.switch-lang__icon img {
  vertical-align: top;
  margin-top: -0.1rem;
}

.switch-lang__space {
  width: .5rem
}

.switch-lang__text {
  width: 24px;
  text-align: center;
}

.switch-lang .switch-lang__options {
  display: none;
}

.switch-lang:hover .switch-lang__options {
  display: block;
  width: 24px;
  margin-left: -5px;
}

.switch-lang:hover .switch-lang__arrow {
  display: none;
}

.switch-lang__arrow {
  position: absolute;
}

.switch-lang__arrow_up {
  top: calc(50% - 1.2rem);
  right: 7px;
}

.switch-lang__arrow_down {
  bottom: calc(50% - 1.1rem);
  right: 7px;
}

.switch-lang__options-up {
  bottom: calc(50% - 1.2rem);
  background-color: #333;
}

.switch-lang__options-down {
  top: calc(50% - 1.2rem);
  background-color: #262626;
}

.switch-lang__options {
  position: absolute;
  right: 0;
}

.switch-lang__option-text {
  display: block;
  color: #fff;
  font-size: 1.3rem;
  width: 100%;
  text-align: center;
}

.switch-lang__option-text:hover {
  background-color: #3e3e3e;
}

.switch-lang__options-up .switch-lang__option-text {
  padding: 0.2rem 0 0 0;
}

.switch-lang__options-down .switch-lang__option-text {
  padding: 0 0 0.2rem 0;
}

.switch-lang__arrow-up {
  display: block;
  padding: 0;
  margin: 0 auto -0.1rem;
}

.switch-lang__arrow-down {
  display: block;
  padding: 0;
  margin: -0.1rem auto 0;
}

.footer {
  background-color: #333
}

.footer a {
  color: #fff
}

.footer__nav .nav__item {
  padding: .9rem .75rem
}

.footer__nav .nav__item--active {
  color: #000
}

.footer__nav .nav__item--logo {
  padding-right: 2rem !important
}

.footer__nav .nav__item--logo img {
  height: 1.2rem
}

.footer__icons {
  margin-top: .5rem;
  margin-bottom: -5px
}

.footer__icon {
  height: 1.4rem;
  margin-right: .75rem;
  fill: #fff
}

.footer__icon--youtube {
  height: 1.2rem;
  margin-top: .1rem
}

.footer__icon:hover {
  transform: scale(1.1)
}

.footer__link {
  text-decoration: underline
}

@media (max-width:992px) {
  .footer__nav {
      display: none !important
  }
  .footer__social {
      margin-right: 1rem
  }
  .footer__last-line {
      display: block !important;
      flex-direction: column
  }
  .footer__period {
      margin-top: 2rem
  }
}

.nav {
  width: 100%;
  background-color: rgba(0, 0, 0, .85);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.nav__item {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding: 1.7rem .75rem;
  color: #fff;
  cursor: pointer
}

.nav__item:last-child {
  padding-right: 0
}

.nav__item:first-child {
  padding-left: 0
}

.nav__item--active {
  color: #000;
  background-color: hsla(0, 0%, 100%, .99)
}

.nav__item--logo {
  padding-right: 1rem !important
}

.nav__item--logo img {
  height: 25px
}

:root {
  --vh: 0;
  --vh100: 0
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  width: 100%;
  margin: 0;
  overflow-x: hidden;
  color: #000;
  background-color: #333;
  -webkit-tap-highlight-color: transparent
}

*,
body,
html {
  font-family: "Calibri Custom", sans-serif
}

html {
  font-size: 16px
}

@media (max-width:1200px) {
  html {
      font-size: 14px
  }
}

@media (max-width:350px) {
  html {
      font-size: 13px
  }
}

@media (min-width:1921px) {
  html {
      font-size: calc(.77rem + .28vw)
  }
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow-x: hidden
}

.page__filler {
  flex: auto;
  background-color: #fff
}

img {
  max-width: 100%;
  height: auto
}

a {
  color: #7bbde2;
  text-decoration: none;
  outline: none
}

ul {
  padding-left: 0
}

@media (min-width:993px) {
  ul {
      padding-left: 1.3rem
  }
}

* {
  box-sizing: border-box;
  scrollbar-color: #fff #000;
  scrollbar-width: thin;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%
}

::-webkit-scrollbar {
  width: 5px
}

::-webkit-scrollbar-track {
  background-color: #000
}

::-webkit-scrollbar-thumb {
  background-color: #fff
}

::-webkit-scrollbar-button {
  display: none
}

::-webkit-scrollbar-corner {
  background-color: #000
}

.container {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto
}

@media (min-width:1921px) {
  .container {
      width: 60%;
      max-width: 60%
  }
}

@media (max-width:1200px) {
  .container {
      padding: 0 2rem
  }
}

.main-text {
  font-size: 1.2rem;
  font-family: "Calibri Custom", sans-serif;
  line-height: 1.5rem
}

@media (min-width:1921px) {
  .main-text {
      font-size: 1.28rem
  }
}

.main-title {
  font-size: 2.8rem;
  font-family: "DIN Condensed", sans-serif;
  line-height: 3rem;
  text-transform: uppercase
}

@media (max-width:1200px) {
  .main-title {
      font-size: 2.5rem
  }
}

@media (max-width:992px) {
  .main-title {
      font-size: 2.2rem;
      line-height: 2.5rem
  }
}

.fl {
  display: flex
}

.fl-auto {
  flex: auto
}

.upper {
  text-transform: uppercase
}

.bold {
  font-weight: 700
}

.din-condensed {
  font-family: "DIN Condensed", sans-serif
}

.mt-1 {
  margin-top: 1rem !important
}

.mt-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important
}

.mb-2 {
  margin-bottom: 2rem !important
}

.pr-1 {
  padding-right: 1rem !important
}

.pr-2 {
  padding-right: 2rem !important
}

.pl-2 {
  padding-left: 2rem !important
}

.pl-3 {
  padding-left: 3rem !important
}

.white-color {
  color: #fff
}

.no-select {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .85)
}

.hidden {
  display: none !important
}

@media (max-width:992px) {
  .md-hidden {
      display: none !important
  }
}

.parallax {
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 1px;
  perspective-origin: 0 0
}

.parallax__img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  transform-origin: 0 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  will-change: transform
}

.page {
  background-color: #fff
}

.page--padding>:first-child.block {
  padding-top: 4rem
}

.page--padding>:first-child:not(.block) {
  padding-top: 1rem
}

.page--padding>:last-child:not(.block) {
  padding-bottom: 2rem
}

@media (max-width:992px) {
  .page--padding>:first-child.block {
      padding-top: 1.64rem !important
  }
  .page--padding>:first-child:not(.block) {
      padding-top: 1.2rem
  }
  .page--padding>:last-child:not(.block) {
      padding-bottom: 1.5rem
  }
}

.block {
  position: relative
}

.block__container {
  position: relative;
  display: flex;
  height: auto;
  padding-top: 3%;
  padding-bottom: 3%
}

@media (max-width:1200px) {
  .block__container {
      padding-top: 3.5rem;
      padding-bottom: 3.5rem
  }
}

.block__img {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  max-width: 200%;
  height: 100%;
  max-height: 200%;
  pointer-events: none
}

.block__additionalGradient {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  width: 100%;
  height: 65%
}

.block__button,
.block__children,
.block__text,
.block__title {
  z-index: 3
}

.block__children,
.block__text {
  margin-top: 1rem
}

.block__info {
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 58%
}

@media (min-width:993px) {
  .block__button {
      margin-top: 8rem;
      margin-bottom: 8rem
  }
  .block--mirrored .block__container {
      flex-direction: row-reverse
  }
  .block--mirrored .block__img {
      left: 0
  }
  .block--mirrored .block__info {
      display: flex;
      flex-direction: column;
      align-items: flex-end
  }
  .block--mirrored .block__text,
  .block--mirrored .block__title {
      text-align: right
  }
}

@media (max-width:992px) {
  .block__container {
      align-items: flex-end
  }
  .block__additionalGradient {
      display: block
  }
  .block__info {
      justify-content: flex-end;
      max-width: 54%
  }
  .block__children,
  .block__text {
      padding-bottom: 1rem
  }
}

@media (max-width:992px) and (orientation:portrait) {
  .block__info {
      max-width: 95%
  }
}

.button {
  display: inline-block;
  min-width: 100px;
  padding: .5rem .75rem;
  font-size: 1.3rem;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.button--white {
  color: #000;
  background-color: #fff
}

.button--white:active {
  color: rgba(0, 0, 0, .8)
}

.button--black {
  color: #fff;
  background-color: #000
}

.button--black:active {
  color: hsla(0, 0%, 100%, .8)
}

.button--disabled {
  cursor: default;
  opacity: .5;
  pointer-events: none
}

.button:hover {
  transform: scale(1.02)
}

.button:active {
  outline: none
}

.input {
  width: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  border-radius: 0
}

.input--bordered {
  border-bottom: 1px solid #000;
  outline: none
}

.input--bordered::-webkit-input-placeholder {
  color: grey
}

.input--bordered:-ms-input-placeholder {
  color: grey
}

.input--bordered::-ms-input-placeholder {
  color: grey
}

.input--bordered::placeholder {
  color: grey
}

.privacy-page a {
  text-decoration: underline
}

.privacy-page .sub-title {
  font-weight: 700;
  font-size: 2.25rem;
  font-style: italic;
  line-height: 2.5rem
}

@media (max-width:1200px) {
  .privacy-page .sub-title {
      font-size: 2rem
  }
}

@media (max-width:992px) {
  .privacy-page .sub-title {
      font-size: 2.2rem;
      line-height: 2.5rem
  }
}

.privacy-page--full {
  padding-top: 2rem;
  padding-bottom: 2rem
}

#nprogress {
  pointer-events: none
}

#nprogress .bar {
  background: #29d;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px
}

#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  transform: rotate(3deg) translateY(-4px)
}

#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border-color: #29d transparent transparent #29d;
  border-style: solid;
  border-width: 2px;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner .4s linear infinite;
  animation: nprogress-spinner .4s linear infinite
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative
}

.nprogress-custom-parent #nprogress .bar,
.nprogress-custom-parent #nprogress .spinner {
  position: absolute
}

@-webkit-keyframes nprogress-spinner {
  0% {
      -webkit-transform: rotate(0deg)
  }
  to {
      -webkit-transform: rotate(1turn)
  }
}

@keyframes nprogress-spinner {
  0% {
      transform: rotate(0deg)
  }
  to {
      transform: rotate(1turn)
  }
}